import React from "react"
import GMap from "../GMap"

import "./styles.scss"

class ContactMap extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showInfoWindow: true
        }
    }

    toggleInfoWindow = () => this.setState({showInfoWindow: !this.state.showInfoWindow })

    render() {
        return (
            <section className="container contact-map">
                <div className="row">
                    <div className="col-md-8 maps-left">
                        <GMap 
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            mapCenter={{ lat: -34.915445, lng: 150.585723 }}
                            isMarkerShown
                            markerCenter={{ lat: -34.915445, lng: 150.585723 }}
                            zoom={15}
                            toggleInfoWindow={this.toggleInfoWindow}
                            showInfoWindow={this.state.showInfoWindow}
                            infoWindowData={
                                <>
                                <h5 className="googlemaps-heading">Precision PDR</h5>
                                <p className="googlemaps-text">6/35 Cumberland Avenue<br/>
                                South Nowra NSW 2540</p>
                                </>
                            }
                        />
                    </div>
                    <div className="col-md-4 text-center maps-right">
                        <h3>Contact Details</h3>
                        <p>6/35 Cumberland Avenue<br/>South Nowra NSW 2540</p>
                        <p>0413 600 097</p>
                        <a className={this.props.enquire === "true" ? "btn btn-primary" : "btn btn-primary hidden"} role="button" href="/contact">Enquire</a>
                    </div>
                </div>
            </section>
        )
    }
}
 
export default ContactMap