import React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

import "./styles.scss"

const GMap = withScriptjs(withGoogleMap((props) => (
    <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.mapCenter}
    >

        {props.isMarkerShown && <Marker 
            onClick={props.toggleInfoWindow}
            position={props.markerCenter} > 
            {props.showInfoWindow && <InfoWindow 
                onCloseClick={props.toggleInfoWindow} >
                    {props.infoWindowData}
                </InfoWindow>}
            </Marker>
        }
    </GoogleMap>
)))

export default GMap