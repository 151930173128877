import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Quote from "../components/Quote"
import ContactMap from "../components/ContactMap"
import Header from "../components/Header"

const IndexPage = ({location}) => {
  return (
  <Layout location={location}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header />
    <section className="container page">
      <div className="row">
        <div className="col-12 text-center">
          <h1>Welcome to the exciting world of Precision Dent Removal</h1>
          <p>A fast, inexpensive and enviromentally friendly way of repairing unsightly carpark dents, dings, minor panel damage as well as hail damage from your Car, 4wd or Motorbike.</p>
          <p>With specialist tools and techniques, that have been used Worldwide for nearly 5 decades, this service is now available to the people of the Shoalhaven with most jobs done in less than 1 - 2 hours.</p>
          <p>Please don't hesitate to call for a no obligation, free quote today.</p>
        </div>
      </div>
    </section>
    <Quote />
    <ContactMap enquire="true"/>
  </Layout>
  )
}

export default IndexPage
