import React from "react"

import "./styles.scss"
import precisionute from "../../images/precision-ute.jpg"

const Quote = () => (
    <section className="quote-container">
        <div className="container">
            <div className="row">
                <div className="col-md-8 text-center quote-left">
                    <div>
                        <p className="quote-text">To all my fellow colleagues, family and friends who have supported me, I thank you. I look forward to the opportunity in continuing to offer my expertise services to my valued customers and also to my prospective customers throughout the Shoalhaven in the future</p>
                        <p className="cite">- Scott Woods, Owner / Operator</p>
                    </div>
                </div>
                <div className="col-md-4 text-center quote-right">
                    <img className="profile-image" src={precisionute} alt="Precision Ute"></img>
                </div>
            </div>
        </div>
    </section>
)

export default Quote